@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.brand-text span {
  font-size: 32px;
  font-weight: bold;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(45deg, #f68f19, #e6c027);
}

.logo-container {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  background-clip: text;
  
}

.logo-image {
  max-width:100px;
}

:root {
  --mainColor: #fff7d5;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #000000;
}

header {
  font-size: 15px;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  transition: color 0.3s;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  z-index: 2;
}

header div, nav {
  display: flex;
  align-items: center;
}

nav .right-btn {
  margin-left: auto;
}

@media only screen and (max-width: 1280px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    z-index: 1;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .right-btn {
    margin-left: 0;
    margin-top: 1rem;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

nav .right-btn {
  margin-left: auto;
}

@media only screen and (max-width: 1280px) {
  nav .right-btn {
    margin-left: 0;
    margin-top: 1rem;
  }
}

nav .nav-link.right-btn {
    font-size: 20px;
  margin-left: auto;
}

@media only screen and (max-width: 1280px) {
  nav .nav-link.right-btn {
    margin-left: 0;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 1280px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .right-btn {
    margin-left: 0;
    margin-top: 1rem;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}

nav .right-btn {
  margin-left: auto;
}

@media only screen and (max-width: 1280px) {
  nav .right-btn {
    margin-left: 0;
    margin-top: 1rem;
  }
}

nav .nav-link.right-btn {
  margin-left: auto;
}

@media only screen and (max-width: 1280px) {
  nav .nav-link.right-btn {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.brand-texts span {
    font-size: 60px;
    font-weight: bold;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(45deg, #f8ab1e,#e9d011 ,#f3452a);
  }
  
  .logos-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
  }

  .brands-texts span {
    font-size: 60px;
    font-weight: bold;
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(45deg ,#e95740,#f86910,#ec9b05);
}

@media (max-width: 640px) {
    .brands-texts span {
        font-size: 32px;
    }
}

.logos-containers {
    display: flex;
    align-items: center;
    padding: 0.5rem;
}



.homebrand-text span {
  font-size: 32px;
  font-weight: bold;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(45deg, #f68f19, #e6c027);
}

.homelogo-container {
  display: flex;
  align-items: center;
  font-size: 30px;
  background-clip: text;
color: rgb(44, 40, 40);
 font-weight: 600;
}

.desclogo-container {
  display: flex;
  align-items: center;
  font-size: 20px;
  background-clip: text;
color: rgb(44, 40, 40);
 font-weight: 500;
}

.home1brand-text span {
  font-weight: bold;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(45deg, #fd9013, #cba819);
}

.desc1logo-container {
  display: flex;
  align-items: center;
  background-clip: text;
color: rgb(44, 40, 40);
 font-weight: 600;
}


.logo-containerss {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  background-clip: text;
  
}

.logo-imagess {
  max-width: 100px;
}

@media (min-width: 1280px) {
  .logo-containerss {
    display: none;
  }
}

.sibrand-text span {
  font-size:40px;
  font-weight: bold;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(45deg, #f68f19, #e6c027);
}