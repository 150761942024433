* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.marquee {
    overflow: hidden;
}

.marquee-content {
    display: flex;
    animation: scrolling 10s linear infinite;
}

.marquee-item img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

@keyframes scrolling {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Adjust styles as needed */

.marquee-card {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 15px;
}

.marquee-row {
    display: flex;
    overflow-x: hidden;
    animation: marquee 5s linear infinite;
}
.custom-outer-container {
    width: 90%; /* Adjust the width as needed */
    max-width: 90%;
  }
  
.marquee-item {
    flex: 0 0 25%;
    margin-right: 7px;
}

.image-container {
    width: 100%;
    height: 70%;
    border-radius: 10px;
    overflow: hidden;
}

.marquee-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 8px;
}

@keyframes marquee {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.image12-container {
    width: 210px; /* Adjust the width as needed */
  }
  
  .animated-image {
    width: 100%;
    max-width: 100%; /* Ensure the image does not exceed its container */
    animation: bounce 1s infinite alternate; /* Example animation, adjust as needed */
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px); /*  animation, adjust as needed */
    }
  }
